.btn-red-w305-h56{
    color: #FFFFFF !important;
    background-color: #FA6E6E !important;
    border: 1px solid #FA6E6E !important;
    max-width: 335px;
    height: 8vw;
    max-height: 56px;
    padding: initial;
    border-radius: 15px;
    font-size: 19px;
    font-weight: 700;
  }
.btn-yellow-w244-h56{
    color: #FFFFFF !important;
    background-color: #F3B852 !important;
    border: 1px solid #F3B852 !important;
    max-width: 265px;
    height: 8vw;
    max-height: 56px;
    padding: initial;
    border-radius: 15px;
    font-size: 19px;
    font-weight: 700;
  }
  .btn-blue-w326-h56{
    color: #FFFFFF !important;
    background-color: #4B55A1CC !important;
    border: 1px solid #4B55A1CC !important;
    max-width: 335px;
    height: 8vw;
    max-height: 56px;
    padding: initial;
    border-radius: 15px;
    font-size: 19px;
    font-weight: 700;
  }

  .btn-blue-w150-h50-bv {
    color: #4B55A1CC !important;
    background-color: #E9E7FD !important;
    border: 2px solid #4B55A1CC !important;
    max-width: 270px;
    height: 4vw;
    max-height: 60px;
    padding: initial;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 700;

  }
  .Text-light-gray-24-4-normal {
    font-style: normal;
    font-weight: 400;
    font-size: calc(min(24.4px, 0.9rem + 0.759375vw));
    color: #777777;
  }

  .btn-yellow-w244-h56:focus, .btn-yellow-w244-h56:active{
    background-color: #F3B852 !important;
  }
  .btn-red-w305-h56:focus, .btn-red-w305-h56:active{
    background-color: #FA6E6E !important;
  }
  .btn-blue-w326-h56:focus, .btn-blue-w326-h56:active{
    background-color: #4B55A1 !important;
  }