.custom-table {
    border-collapse: collapse;
  }
  
  .custom-table .table td {
    border: none

  }
  .custom-table .table td:first-child {
    border-right: 1px solid #FFF !important; 
  }

  // Market Admin Badges

  .custom-badge-reset {
    color: #6188ED !important;
    background-color: #b8bcc5 !important;
  }

  .custom-badge-pause {
    color: #E64449!important;
    background-color: #ec3e4479 !important;
  }

  .checkbox-disabled {
    cursor: not-allowed;
    color: #b0b0b0; 
  }
  
  .checkbox-disabled input[type="checkbox"] {
    background-color: #e0e0e0; 
    border-color: #b0b0b0; 
    cursor: not-allowed;
  }
  