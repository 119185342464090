.landing-footer{
  min-height: 1110px;
  .op-footer-text{
    width: 78%;
    margin: auto;
    margin-bottom: 60px;
    h1{
      font-weight: 700;
      line-height: calc(1.61484375rem + 1.096875vw);
      letter-spacing: 0em;
      text-align: center;
      color: #FFFFFF;
    }
    p{
      font-size: calc(1.118rem + 0.759vw);
      font-weight: 400;
      line-height: calc(1.077rem + 0.731vw);
      letter-spacing: 0em;
      text-align: center;
      color: #FFFFFF;
    }
  }
  
  .card-benefits{
    margin: 26px 30px;
    width: 80vw;
    max-width: 486px;
    height: 257px;
    border-radius: 38px;
    // border: 2px #FA6E6E solid !important;
    .card-title{
      margin: 1.11vw;
      line-height: calc(1.2836rem + 0.872vw);
      svg{
        max-width: 18vw;
      }
    }
  }
  &.lightcolor{
      .op-footer-text{
          h1{
              color: #4B55A1;
          }
          p{
              color: #000000;
          }
      }
  }
}

@media (min-width: 1200px){
  .landing-footer{
    .op-footer-text{
      h1{
        line-height: 2.4375rem;
      }
      p{
        font-size: 1.6875rem;
        line-height: 1.625rem;
      }
    }
    
    .card-benefits{
      .card-title{
        line-height: 1.9375rem;
      }
    }
  }
}