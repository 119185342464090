.Text-red {
  color: #FA6E6E !important;
}

.Text-blue {
  color: #4B55A1 !important;
}

.Text-yellow {
  color: #F3B852 !important;
}

.Text-white {
  color: #FFFFFF !important;
}

.Text-gray-resumen {
  color: #606060 !important;
}

:root {
  --length: 1;
}

.Text-16-4-normal {
  font-style: "PublicSans";
  font-weight: 500;
  font-size: 16px;
  color: #000;
  line-height: 21px;
}

.Text-gray-20-4-normal {
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.828rem + 0.5625vw);
  color: rgba(9, 8, 8, 0.53);
}

.Text-blue-22-normal {
  font-style: normal;
  font-weight: 700;
  font-size: calc(0.9109375rem + 0.61875vw);
  text-align: center;
  color: #4B55A1;
}

.Text-gray-24-normal {
  font-style: normal;
  font-weight: 400;
  font-size: calc(1rem + 0.675vw);
  color: rgba(9, 8, 8, 0.53);
}

.Text-26-normal {
  font-style: normal;
  font-weight: 400;
  font-size: calc(min(0.825rem + 1.333vw, 1.625rem));
  line-height: calc(min(0.590rem + 2.143vw, 2.219rem));
}

.Text-dark-gray {
  color: #4B465C !important;
}

.Text-blue {
  color: #4B55A1 !important;
}

.Text-26-w5 {
  font-weight: 500;
  font-size: 1.625rem;
  line-height: calc(min(0.590rem + 2.143vw, 2.219rem));
}

.Text-26-w4 {
  font-weight: 400;
  font-size: calc(min(0.825rem + 1.333vw, 1.625rem));
  // line-height: calc(min(0.590rem + 2.143vw, 2.219rem));
}

.Text-gray {
  color: #5E5873;
}

.Text-22-normal {
  font-style: normal;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: calc(min(32px, 0.290rem + 2.143vw));
  color: #4B465C;
}

.Text-22-w5 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
}

.Text-4B465C {
  color: #4B465C;
}

.Text-gray-27-7-normal {
  font-style: normal;
  font-weight: 700;
  font-size: calc(1.146953125rem + 0.7790625vw);
  text-align: center;
  line-height: calc(1rem + 0.675vw);
  color: rgba(9, 8, 8, 0.53);
}

.Text-gray-27-normal {
  font-style: normal;
  font-weight: 700;
  font-size: calc(1.11796875rem + 0.759375vw);
  color: rgba(9, 8, 8, 0.53);
}

.Text-30-normal {
  font-style: normal;
  font-weight: 700;
  font-size: calc(min(1.242rem + 0.844vw, 1.875rem)) !important;
  line-height: calc(min(1.242rem + 0.844vw, 1.875rem)) !important;
}

.Text-22-w4 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1.375rem !important;
}

.Text-22-w6 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 1.375rem !important;
}

.Text-20-w4 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1.25rem !important;
}

.Text-18-w4 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1.125rem !important;
}

.Text-16-w4 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.Text-30-normal-w5 {
  font-style: normal;
  font-weight: 500;
  font-size: calc(min(1.242rem + 0.844vw, 1.875rem)) !important;
  line-height: calc(min(1.242rem + 0.844vw, 1.875rem)) !important;
}

.Text-blue-28-7-normal {
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  text-align: center;
  color: #4B55A1;
}

.Text-yellow-32-7-normal {
  font-style: normal;
  font-weight: 700;
  font-size: calc(1.325rem + 0.958vw);
  text-align: center;
  color: #F3B852;
}

.Text-yellow-26-6-normal {
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;
  text-align: center;
  color: #F3B852;
}

.Text-red-26-6-normal {
  font-style: normal;
  font-weight: 600;
  font-size: 1.625rem;
  text-align: center;
  color: #FA6E6E;
}

.Text-red-32-7-normal {
  font-style: normal;
  font-weight: 700;
  font-size: calc(1.325rem + 0.958vw);
  text-align: center;
  color: #FA6E6E;
}

.Text-blue-34-normal {
  font-style: normal;
  font-size: 2.125rem;
  line-height: calc(1.99rem + 1.35vw);
  color: #4B55A1;
}

.Text-42-variable-normal {
  font-style: normal;
  font-size: calc(min(0.3536rem + 2.857vw, 42px));
  font-weight: 400;
}

.Text-40-variable-normal {
  font-style: normal;
  font-size: calc(min(0.3536rem + 2.857vw, 40px));
  font-weight: 400;
}

.Text-45-normal {
  font-style: normal;
  font-weight: 600;
  font-size: calc(min(45px, 1.86328125rem + 1.265625vw));
}

.Text-52-adaptable {
  font-style: normal;
  font-size: min((1.193rem * (7.5 / var(--length)) + 2.286vw * (7.5 / var(--length))), 3.250rem);
}

.Text-input-gray-1-10,
.Text-input-gray-1-10:focus {
  border: 1px solid #DBDADE;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  font-style: normal;
  font-size: 1.25rem;
  outline: none;
  color: rgba(9, 8, 8, 0.53)
}

@media (min-width: 1200px) {
  .Text-blue-28-7-normal {
    font-size: 2.3rem;
  }

  .Text-blue-34-normal {
    font-size: 2.125rem;
    line-height: 3rem;
  }

  .Text-blue-30-normal {
    font-size: 1.875rem;
    color: #4B55A1;
  }

  .Text-gray-27-normal {
    font-size: 1.69rem;
  }

  .Text-gray-20-4-normal {
    font-size: 1.25rem;
  }

  .Text-gray-27-7-normal {
    font-size: 1.73125rem;
    line-height: 1.5rem;
  }

  .Text-input-gray-1-10,
  .Text-input-gray-1-10:focus {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}