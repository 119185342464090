//-------------------------------------------- section 1 --------------------------------------------
.landing-header {
  padding-top: 1px;

  .box-text {
    margin-top: 167px;
    color: #FFFFFF;
    margin-left: 9.3%;

    .title {
      font-size: calc(1.164rem + 5.41vw);
      font-weight: 600;
      line-height: calc(3.19rem + 2.166vw);
      letter-spacing: 0em;
      text-align: left;
    }

    .text {
      margin-top: 2.8vw;
      font-size: calc(0.95rem + 0.65vw);
      font-weight: 400;
      line-height: calc(1.03rem + 0.7vw);
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .box-img {
    margin-left: auto;
    margin-right: 2.5%;
    max-height: 231px;
    height: 14.438rem;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .landing-header {
    .box-text {
      margin-top: 167px;
      color: #FFFFFF;
      margin-left: 5rem;

      .title {
        font-size: 4rem;
        line-height: 4.8125rem;
      }

      .text {
        margin-top: 40px;
        font-size: 1.44rem;
        font-weight: 400;
        line-height: 1.5625rem;
      }
    }
  }
}

//-------------------------------------------- section 2 --------------------------------------------
.input-cash {
  max-width: 562px;
}

.output-row{
  border-bottom: 2px solid black;
  max-width: 562px;
  width: 80vw;
}

.input-cash::placeholder {
  color: rgba(9, 8, 8, 0.29);
}

.input-cash-edited{
  background-color: #e8e8e8;
}
.input-cash-edited > *{
  background-color: #e8e8e8;
}
.select-landing {
  max-width: 585px;
  width: 80vw;
  margin-top: min(2vw, 14px);
  border: 1px solid #DBDADE;
  border-radius: 8px;
  font-style: normal;
  font-size: 1.25rem;
  color: rgba(9, 8, 8, 0.29);
  outline: none;
}
.select-placeholder-text {
  color: #c3c3cc;
  margin-left: 5px;
  }

.logged-in{
  .input-cash{
    max-width: 100%;
  }
  .output-row{
    max-width: 100%;
  }
}

.sec-2-container{
  background-color: #FFFFFF;

  .outer-wrap {
    margin: auto;
    position: relative;
    max-width: 1440px;

    .inner-wrap {
      margin: auto;
      max-width: 1390px;
      min-height: min(110vw, 566px);

      .section-simulation {
        min-width: min(562px, 90vw);

        .hdr-title-box {
          margin-top: 5.41%;
        }

        .input-around-box {
          margin-top: min(5vw, 54px);
        }

        .installments-box {
          margin-top: 2.7vw;
        }

        .container-btn {
          margin-top: auto;
        }
      }

      .section-loan-options {
        padding-left: 0px;
        padding-right: 0px;
        // min-width: min(520px, 90vw);

        .row-titles {
          margin-top: auto;
          max-width: 500px;
          width: 90vw;
          height: 24px;
          font-style: normal;
          font-weight: 700;
          font-size: min(0.93vw, 13px);
          line-height: 12px;
          letter-spacing: .23px;

          .col-3 {
            padding: 0px;
            text-align: center;
          }

          .text-blue {
            color: #4B55A1;
          }

        }

        .container-btn {
          margin: auto;
          margin-bottom: 0;
        }
      }
    }
  }
}

.grp-btn-radio-loan {
  max-width: 562px;
  width: 80vw;
  height: 5.14vw;
  min-height: 35px;
  max-height: 52px;
  margin-top: min(2vw, 30px);
}

.btn-radio-loan {
  color: #4B465C !important;
  background-color: #CACCD9 !important;
  border: 1px solid #CACCD9 !important;
}

.btn-radio-loan.active {
  color: #FFFFFF !important;
  background-color: #4B55A1 !important;
  border: 1px solid #4B55A1 !important;
}

.grp-btn-ratio {
  width: 90vw;
  max-width: 500px;
  height: min(12vw, 52px);
  margin-top: min(2vw, 18px);

  &.first-btn {
    margin-top: 0px;
  }

  :disabled {
    opacity: 1;
  }

  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.grp-btn-ratio-0,
.grp-btn-ratio-1,
.grp-btn-ratio-2,
.grp-btn-ratio-3 {
  color: green !important;
  background-color: red !important;
  border: 1px solid yellow !important;
}

.btn-ratio-loan-0,
.btn-m-cost-0 {
  text-align: left;
  padding: inherit;
  padding-left: min(2vw, 1.5rem);
  width: 23.4vw;
  max-width: 130px;
  font-weight: 700;
  font-size: min(calc(.5rem + 0.928vw), 13px);
  line-height: min(calc(.8rem + 2.86vw), 12px);
  text-transform: uppercase;
}

.btn-ratio-loan-0,
.btn-ratio-loan-2,
.btn-m-cost-0,
.btn-m-cost-2 {
  color: #4B55A1 !important;
  background-color: #F1F1F1 !important;
  border: 1px solid #CACCD9 !important;
}

.btn-ratio-loan-3,
.btn-m-cost-3 {
  color: #939393 !important;
  background-color: #F1F1F1 !important;
  border: 1px solid #CACCD9 !important;
}

.btn-ratio-loan-1,
.btn-m-cost-1 {
  color: #F1F1F1 !important;
  background-color: #4B55A1 !important;
}

.btn-ratio-loan-1,
.btn-ratio-loan-2,
.btn-ratio-loan-3 {
  padding-left: 0px;
  padding-right: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: min(calc((6/var(--word-length))*(.7rem + 1.86vw)), 26px);
  line-height: min(calc(0.6625rem + 0.45vw), 16px);
}

.btn-m-cost-1,
.btn-m-cost-2,
.btn-m-cost-3 {
  padding-left: 0px;
  padding-right: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: min(calc((6/var(--word-length))*(0.4rem + 1.86vw)), 22px);
  line-height: min(calc(0.6625rem + 0.45vw), 16px);
}

.btn-red-w356-h77 {
  font-weight: 500;
  font-size: min(calc(0.95rem + 0.65vw), 23px);
  line-height: 28px;
  color: #FFFFFF !important;
  background-color: #FA6E6E !important;
  border: 1px solid #FA6E6E !important;
  width: 50vw;
  max-width: 356px;
  height: 11vw;
  max-height: 77px;
  padding: initial;
  border-radius: min(2.14vw, 15px);
  //deberia seaparase en nested
  margin-bottom: 11px;
}

.btn-red-w356-h77:focus,
.btn-red-w356-h77:active,
.btn-red-w356-h77.active {
  background-color: #FA6E6E !important;
}

.btn-red-w356-h76{
  max-height: 76px;
  max-width: 356px;
  font-weight: 500;
  font-size: min(calc(0.95rem + 0.65vw), 23px);
  line-height: 28px;
  color: #FFFFFF !important;
  background-color: #FA6E6E !important;
  border: 1px solid #FA6E6E !important;
  width: 50vw;
  height: 11vw;
  padding: initial;
  border-radius: min(2.14vw, 15px);
  //deberia seaparase en nested
  margin-bottom: 11px;
}

.btn-red-w320-h64:focus, .btn-red-w356-h76:active, .btn-red-w320-h64.active{
  background-color: #FA6E6E !important;
}

//-------------------------------------------- section 3 --------------------------------------------
.lnd-prestamos .sec-3-container, .lnd-page.sec-3-container{
  position: relative; 
  min-height: 900px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  
  .container-front{
    position: relative; 
    min-height: 900px; 
    max-width: 1440px; 
    margin: auto;

    .title {
      margin-top: 50px;
      line-height: 40px;
    }

    .module-benefits {
      min-height: 176px;
      max-width: 1048px;
      margin: auto;

      .benefit {
        width: 176px;
        max-width: 30vw;
        margin-top: min(7.16vw, 103.12px);
      }

      .Text-gray-27-7-normal {
        line-height: min(calc(1.4rem + 0.96vw), 34px);
        margin-top: min(6vw, 26px);
      }
    }

    .module-requirements {
      max-width: 1356px;
      min-height: 600px;
      margin: auto;

      .col-5 {
        max-width: 380px;
        width: 80vw;
      }

      .box-text {
        max-width: 500px;

        .title {
          text-align: left;
          margin-bottom: 20.5px;
        }

        .dot-text {
          line-height: min(calc(2.40rem + 1.63vw), 58px);
        }
      }

      .box-img {
        width: 503px;
        max-width: 90vw;
        max-height: 90vw;
        height: 503px;
        margin-bottom: 5px;
      }
    }

  }

  .container-background {
    position: absolute;
    left: 0px;
    bottom: -25px;
    z-index: -1;
  }
}

//-------------------------------------------- section 4 --------------------------------------------
.sec-4-container {
  margin: auto;
  background-color: #4B55A1;
}

//to be reusable, the content goes outside as steandalone
//El footer tiene su propio archivo