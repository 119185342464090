.card-my-investments {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	min-height: 255px;
	background-color: #4B55A1;
}

.card-monthly-income-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 23.5px;
	line-height: 24px;
	color: #4B55A1;
}

.card-next-instalment-title {
	font-weight: 600;
	font-size: 20px;
	line-height: 40px;
	color: #FB4242;
}

.card-total-instalment-title {
	font-weight: 600;
	font-size: 20px;
	line-height: 52px;
	color: #4B55A1;
}

.card-next-instalment {
	width: auto;
	position: relative;
	box-sizing: border-box;
	height: 100%;

}

.card-total-investments {
	position: relative;
	box-sizing: border-box;

	@media (min-width: 1200px) {
		min-width: 285px;
		margin-left: -40px;
	}
}

//   .card-total-investments::before {
// 	content: "";
// 	box-sizing: border-box;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 23%;
// 	background-color: #4B55A1;
// 	border-radius: 5%;
//   }

//   .card-total-investmentsd::after {
// 	content: "";
// 	box-sizing: border-box;
// 	position: absolute;
// 	bottom: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 77%;
// 	background-color: #d9d9d9;
//   }

.overlay-image {
	position: absolute;
	top: 5px;
	right: 60px;
	z-index: 1;
}

.highlight {
	color: #4B55A1;
	font-size: 40px;
	font-weight: 600;
}

@media (max-width: 768px) {
	.card-my-investments {
		width: 100%;
	}

	.card-total-investments {
		width: 100%;
		margin-left: 0%;
	}

	.overlay-image {
		top: 10%;
		right: 33%;
	}

	#card-text-investment {
		font-size: 52px;
		color: red;
	}

}

@media (min-width:360px) {
	.card-body>.card-text-investment {
		font-size: 2vmin;
		color: red;
	}
}

@media (min-width:990px) and (max-width:1600px) {
	.yo-width {
		width: 100%;
	}

	.tu-width {
		width: 50%;
	}
}

.accountSummary {
	@media (min-width:600px) {
		height: 363px;
	}

}

.date-input {
	min-width: 220px !important;
	width: 100% !important;
}

.customTimeline {
	.timeline-item {
		padding-bottom: 8px !important;
		padding-left: 1rem !important;
	}

	.timeline-item:nth-child(1) {
		border-image: linear-gradient(to bottom, #FFF, #28c76f, #F9E10C);
		border-image-slice: 1;
	}
	h6{
		font-size: 15px !important;
	}
	.timeline-item:nth-child(2) {
		border-image: linear-gradient(to bottom, #F9E10C, #F9E10C, #ff9f43 );
		border-image-slice: 1;
	}

	p {
		display: none;
	}

	.timeline-event {
		min-height: 0 !important;
	}
}

.customTableHeader{
	.css-kw1f55-MuiTableCell-root {
		font-family: "Montserrat","Helvetica","Arial",sans-serif;
		text-align: center;
	}
}

.apexcharts-legend {
	width: 100px;
	background: #AAAEC540;
	border-radius: 10px;
	// border: 1px solid #DCDADA;
	padding: 0 15px;
	margin-bottom: 20px;
  }

//rescue button
$green: #7ED321; 
$border-green: #7ED321; 

.custom-green-btn {
  background-color: $green;
  border-color: $border-green;
  border-radius: 6px;
  width: 193px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: white; 
  
  &:hover, &:focus {
    background-color: lighten($green, 10%); 
    border-color: lighten($border-green, 10%);
  }
}