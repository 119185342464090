.absolute-header{
  background-color: rgb(233, 79, 47);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
}

.landing-header{
  .col{
    &.pt{
      flex: 1 0
    }
    &.px0{
      flex: 0 0
    }
  }

  button{
    &:hover{
      background-color: white !important;
      color: rgb(31, 30, 30) !important;
    }
  }
}