.Text-gray-16-4-normal {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(9, 8, 8, 0.53);
}

.Text-gray-21-4-normal {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  color: rgba(9, 8, 8, 0.53);
}

.Text-gray-12-4-normal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(9, 8, 8, 0.53);
}

.Text-blue-18-6-normal {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #4B55A1;
}

.Text-yellow-18-6-normal {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #F3B852 !important;
}

.Text-blue-26-normal {
  color: #4B55A1;
  font-style: normal;
  font-weight: 600;
  font-size: calc(min(1.076rem + 0.731vw, 26px));
  line-height: 22px;
}

.Text-blue-20-normal {
  color: #4B55A1;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
}

.modal-division-line {
  width: 60vw;
  max-width: 400px;
  opacity: 0.5;
  border-top: 2.5px solid #4B55A1;
  margin: 1rem auto;
}

.Text-blue-26-6-normal {
  font-style: normal;
  font-weight: 600;
  font-size: 26PX;
  text-align: center;
  color: #4B55A1;
}

.Text-blue-20-6-normal {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #4B55A1;
}

.text-16 {
  font-size: 1rem !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.btn-red-w260-h40 {
  color: #FFFFFF !important;
  background-color: #FA6E6E !important;
  border: 1px solid #FA6E6E !important;
  width: 200px;
  max-width: 260px;
  height: 40px;
  max-height: 50px;
  padding: initial;
  border-radius: 6px;
}

.btn-yellow-w260-h40 {
  color: #FFFFFF !important;
  background-color: #F3B852 !important;
  border: 1px solid #F3B852 !important;
  min-width: 200px;
  max-width: 260px;
  height: 40px;
  max-height: 50px;
  padding: initial;
  border-radius: 6px;
}

.btn-yellow-simulator {
  color: #FFFFFF !important;
  background-color: #F3B852 !important;
  border: 1px solid #F3B852 !important;
  min-width: 200px;
  height: 3.438rem;
  border-radius: 6px;
  font-size: 1.125rem;
  font-weight: 500;
}

.btn-red-simulator {
  color: #FFFFFF !important;
  background-color: #FA6E6E !important;
  border: 1px solid #FA6E6E !important;
  min-width: 200px;
  height: 3.438rem;
  border-radius: 6px;
  font-size: 1.125rem;
  font-weight: 500;
}

.btn-blue-w90-h50 {
  color: #FFFFFF !important;
  background-color: #4B55A1 !important;
  border: 1px solid #4B55A1 !important;
  width: 90px;
  max-width: 90px;
  height: 11vw;
  max-height: 50px;
  padding: initial;
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 15px;
}

.btn-blue-w150-h50 {
  color: #FFFFFF !important;
  background-color: #4B55A1 !important;
  border: 1px solid #4B55A1 !important;
  width: 150px;
  max-width: 150px;
  height: 11vw;
  max-height: 50px;
  padding: initial;
  border-radius: 6px;
  font-weight: 500;
  margin-right: 10px;
}

.btn-blue-w150-h50-bv {
  color: #4B55A1 !important;
  background-color: #E9E7FD !important;
  border: 1px solid #4B55A1 !important;
  width: 280px;
  max-width: 160px;
  height: 20vw;
  max-height: 50px;
  padding: initial;
  border-radius: 6px;
  font-weight: 500;
  margin-right: 10px;
  font-size: 20px;
}

.btn-outlined-blue-w121-h44 {
  color: #4B55A1 !important;
  font-size: 20px;
  font-weight: 500;
  background-color: #FFF !important;
  border: 2px solid #4B55A1 !important;
  width: 121px;
  height: 44px;
  padding: initial;
  border-radius: 6px;
  // margin-left: 10px;
  // margin-right: 10px;
}

.btn-outlined-blue-w150-h50 {
  color: #4B55A1 !important;
  font-size: 20px;
  font-weight: 500;
  background-color: #FFF !important;
  border: 2px solid #4B55A1 !important;
  width: 150px;
  height: 50px;
  padding: initial;
  border-radius: 6px;
  margin-left: 10px;
}

.btn-blue-w114-h40 {
  color: #FFFFFF !important;
  background-color: #4B55A1 !important;
  border: 1px solid #4B55A1 !important;
  width: 114px;
  max-width: 1140px;
  height: 40px;
  max-height: 50px;
  padding: initial;
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 10px;
}

.btn-red-w260-h40:focus,
.btn-red-w260-h40:active {
  background-color: #FA6E6E !important;
}

.btn-red-simulator:focus,
.btn-red-simulator:active {
  background-color: #FA6E6E !important;
}

.btn-yellow-w260-h40:focus,
.btn-yellow-w260-h40:active,
.btn-yellow-simulator:focus,
.btn-yellow-simulator:active {
  background-color: #F3B852 !important;
}

.btn-blue-w260-h50:focus,
.btn-blue-w260-h50:active,
.btn-blue-w114-h40:focus,
.btn-blue-w114-h40:active {
  background-color: #4B55A1 !important;
}

.modal-confirm-btn-section {
  margin: auto;
}
.light-blue-btn {
  color: #4B55A1 !important;
  border: 1px solid #7367F029 !important;
  border-radius: 6px !important;
  background: #7367F029 !important;
  padding: 0.4rem 1rem !important;
}
.light-blue-btn:focus,
.light-blue-btn:active {
  color: #4B55A1 !important;
  border: 1px solid #7367F029 !important;
  background: #7367F029 !important;
}
.btn-form-bencomunidad {
  border-radius: 6px !important;
  border: 1px solid #7367F0 !important;
  font-size: 20px;
  color: #4B465C !important;
  font-weight: 500;
  padding: 20px !important;
  align-items: center;
  gap: 8px;
  // background-color: white !important;

  max-width: 293px;
  min-height: 125px;

  @media (min-width: 768px) {
    width: 200px;
  }
}

.btn-form-bencomunidad:active,
.btn-form-bencomunidad:focus,
.btn-form-bencomunidad:focus .btn-form-bencomunidad:checked {
  background-color: #E9E7FD !important;
  color: #4B465C !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  background-color: #E9E7FD !important
}


.button-group {
  display: flex;
  max-width: 872px;
  flex-direction: row;
  width: 100%;
}

@media (min-width: 768px) {
  .button-group {
    gap: 60px;
  }
}

.modal-loan {
  .modal-content {
    border-radius: 5%;

    .modal-header {
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;

      .modal-title {
        margin-left: auto;
      }
    }
  }
}

.custom-link {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

.custom-link:hover {
  text-decoration: underline;
  color: #ffffffb5;

}

//modal styles
.modal-content {
  border-radius: 25px;
}

// .account-settings {
//   .nav-pills .nav-link.active {
//     border-color: #4B55A1 !important;
//     background: #4B55A1 !important;
//     box-shadow: 0 0 6px 1px rgba(#4B55A1, 0.6) !important;
//   }

//   .nav-link {
//     max-height: 38px !important;
//   }
// }

.custom-swal-width {
  min-width: 650px; 
  padding: 2rem;
}

.custom-swal-width-login {
  min-width: 550px; 
  padding: 1rem;
}

.custom-swal-icon-size {
  font-size: 0.7rem; /* Tamaño del ícono */
}
