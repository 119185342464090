.Text-center {
	text-align: center !important;
}

.grp-btn-radio-qi {
	margin-top: 30px;
	width: 50vw;
	max-width: 250px;
}

.p-btn {
	padding: 0% 5% 5%;
	display: table;
}

.simulated-table {
	max-width: 615px;
}

.grp-radio-qp {
	margin-top: 38px;
}

.btn-yellow-w356-h77 {
	font-weight: 500;
	font-size: min(calc(0.95rem + 0.65vw), 23px);
	line-height: 28px;
	color: #FFFFFF !important;
	background-color: #F3B852 !important;
	border: 1px solid #F3B852 !important;
	width: 50vw;
	max-width: 356px;
	height: 4.70rem;
	max-height: 77px;
	padding: initial;
	border-radius: min(2.14vw, 15px);
	margin-bottom: 11px;
}

.btn-yellow-w356-h76 {
	max-height: 76px;
	max-width: 356px;
	font-weight: 500;
	font-size: min(calc(0.95rem + 0.65vw), 23px);
	line-height: 28px;
	color: #FFFFFF !important;
	background-color: #F3B852 !important;
	border: 1px solid #F3B852 !important;
	width: 50vw;
	height: 11vw;
	padding: initial;
	border-radius: min(2.14vw, 15px);
	margin-bottom: 11px;
}

.btn-yellow-w356-h77:focus,
.btn-yellow-w356-h77:active,
.btn-yellow-w356-h76.focus,
.btn-yellow-w356-h76:active {
	background-color: #F3B852 !important;
}

.btn-yellow-w320-h64 {
	max-height: 4rem;
	max-width: 20rem;
	font-weight: 500;
	font-size: min(calc(0.95rem + 0.65vw), 23px);
	line-height: 28px;
	color: #FFFFFF !important;
	background-color: #F3B852 !important;
	border: 1px solid #F3B852 !important;
	width: 50vw;
	height: 11vw;
	padding: initial;
	border-radius: min(2.14vw, 15px);
	//deberia seaparase en nested
	margin-bottom: 11px;
}

.btn-yellow-w320-h64:focus,
.btn-yellow-w320-h64:active,
.btn-yellow-w320-h64.active {
	background-color: #F3B852 !important;
}

.btn-white-outlined-w158-h36 {
	max-height: 36px;
	max-width: 158px;
	font-weight: 600;
	font-size: min(calc(0.95rem + 0.65vw), 18px);
	line-height: 28px;
	color: #4B55A1 !important;
	background-color: #FFF !important;
	border: 3px solid #4B55A1 !important;
	height: min(10vw, 36px);
	width: min(38.75vw, 158px);
	padding: initial;
	border-radius: 30px;
	margin-bottom: 11px;
}

.btn-white-outlined2-w158-h36 {
	max-height: 36px;
	max-width: 158px;
	font-weight: 600;
	font-size: min(calc(0.95rem + 0.65vw), 18px);
	line-height: 28px;
	color: rgb(38,47,117) !important;
	background-color: #FFF !important;
	border: 3px solid rgb(38,47,117) !important;
	height: min(10vw, 36px);
	width: min(38.75vw, 158px);
	padding: initial;
	border-radius: 30px;
	margin-bottom: 11px;
}

.btn-white-outlined-w158-h36:focus,
.btn-white-outlined-w158-h36:active,
.btn-white-outlined-w158-h36:hover {
	background-color: #ffffffed !important;
}

.btn-blue-outlined-w158-h36 {
	max-height: 36px;
	max-width: 158px;
	font-weight: 600;
	font-size: min(calc(0.95rem + 0.65vw), 18px);
	line-height: 28px;
	color: #FFF !important;
	background-color: #4B55A1 !important;
	border: 3px solid #FFF !important;
	height: min(10vw, 36px);
	width: min(38.75vw, 158px);
	padding: initial;
	border-radius: 30px;
	margin-bottom: 11px;
}

.btn-blue-outlined2-w158-h36 {
	max-height: 36px;
	max-width: 158px;
	font-weight: 600;
	font-size: min(calc(0.95rem + 0.65vw), 18px);
	line-height: 28px;
	color: #FFF !important;
	background-color:rgb(38,47,117) !important;
	border: 3px solid #FFF !important;
	height: min(10vw, 36px);
	width: min(38.75vw, 158px);
	padding: initial;
	border-radius: 30px;
	margin-bottom: 11px;
}

.btn-blue-outlined-w158-h36:focus,
.btn-blue-outlined-w158-h36:active,
.btn-blue-outlined-w158-h36:hover {
	background-color: #444f9f !important;
}

.font-installments {
	font-size: min(calc(0.4rem + 1.86vw), 22px) !important;
}

.popover {
	max-width: min(400px, 98vw);
	border-radius: 1rem;
}

.popover-background {
	position: relative;
	width: 384px;
	height: 264px;
	border-radius: 1rem;
	background: rgba(248, 168, 21, 0.7803921569);
	border-radius: 1rem;
}

.title-land-invest {
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
}


.view-packages .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child {
	.form-check {
		transform: none !important;
	}

	.form-check-input:checked {
		background-color: #7989ff !important;
		border-color: #7989ff !important;

	}
}

.badge {
	min-width: 21px !important
}