// The dropdown menu
.dropdown {
  [class*='btn-outline-'].dropdown-toggle.dropdown-toggle-split {
    border-left: 0 !important;
  }

  // If don't want dropdown/up arrow
  &.no-arrow {
    .dropdown-toggle {
      &:after {
        display: none;
      }
      i, svg {
        margin-right: 0;
      }
    }
  }
}

// Dropdown menu
.dropdown-menu {
  transform: scale(1, 0);
  box-shadow: $dropdown-box-shadow;
  width: auto; 
  min-width: 8rem; 
  padding: 0;
  margin: 0;

  .dropdown-item {
    width: 100%; 
    display: block;
    cursor: pointer;
    color: $mp-blue;
    background-color: transparent;
    padding: 0.8rem 1.2rem;
    box-sizing: border-box;


    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 

    i, svg {
      color: $mp-blue; 
    }

    &:hover {
      background-color: $mp-blue; 
      color: white;

      i, svg {
        color: white; 
      }
    }
  }


  // Dropdown Header
  .dropdown-header {
    font-weight: 500;
    line-height: 1;
  }

  // Dropdown ::before position
  &[data-popper-placement='bottom-start'],
  &[data-popper-placement='bottom-end'] {
    margin-top: $dropdown_spacing !important;
  }

  &[data-popper-placement='top-start'],
  &[data-popper-placement='top-end'] {
    margin-bottom: $dropdown_spacing !important;
  }

  &[data-popper-placement='right-start'],
  &[data-popper-placement='right-end'] {
    margin-left: $dropdown_spacing !important;
  }

  &[data-popper-placement='left-start'],
  &[data-popper-placement='left-end'] {
    margin-right: $dropdown_spacing !important;
  }
}

// Show
.show {
  > .dropdown-menu {
    transform: scale(1, 1);
    opacity: 1;
    display: block;
  }
  .dropdown-toggle {
    &:focus {
      box-shadow: none;
    }
  }
}

// Dropdown Toggle
.dropdown-toggle {
  &::after {
    border: none !important;
    content: ' ';
    background-image: url(str-replace(str-replace($chevron-down, 'currentColor', $white), '#', '%23'));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    color: $body-color;
    width: 14px;
    height: 11px;
    position: relative;
    top: 1px;
    right: 0px;
    left: 0.714rem;
    padding: 0;
    margin: 0;
    vertical-align: 0;
  }

  // Arrow size according to toggle sizes
  &.btn-lg::after {
    background-size: 18px;
  }

  &.btn-sm::after {
    background-size: 14px;
  }

  &.dropdown-toggle-split::after {
    left: 0;
  }

  &.nav-hide-arrow::after {
    display: none;
  }

  &:focus {
    outline: 0;
  }
}

// DropUp
.dropup {
  position: relative;
  .dropdown-toggle::after {
    background-image: url(str-replace(str-replace($chevron-up, 'currentColor', $white), '#', '%23'));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    width: 14px;
    height: 11px;
    content: '';
    vertical-align: 0.05rem;
  }
  .dropdown-menu {
    min-width: 8rem;
  }
}

// DropStart
.dropstart {
  .dropdown-toggle::before {
    border: none !important;
    background-image: url(str-replace(str-replace($chevron-left, 'currentColor', $white), '#', '%23'));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    width: 14px;
    height: 11px;
    content: '';
    position: relative;
    left: 0;
  }
  .dropdown-menu {
    min-width: 8rem;
  }
}

// DropEnd
.dropend {
  .dropdown-toggle::after {
    border: none !important;
    background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $white), '#', '%23'));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    width: 14px;
    height: 11px;
    content: '';
  }
  .dropdown-menu {
    min-width: 8rem;
  }
}

// Dropdown icon
.dropdown,
.dropup {
  &.dropdown-icon-wrapper {
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-menu {
      min-width: auto;
      .dropdown-item {
        padding: 0.5rem 1.1rem;
        i, svg {
          height: 1.3rem;
          width: 1.3rem;
          font-size: 1.3rem;
        }
      }
    }
  }
}

// Dropdown menu animation for Horizontal menu
.horizontal-menu-wrapper .dropdown-menu,
.header-navbar .dropdown-menu {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideIn;
}

// Hidden dropdown toggle arrow
.dropdown-toggle.hide-arrow,
.dropdown-toggle-hide-arrow > .dropdown-toggle {
  &::before,
  &::after {
    display: none;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
