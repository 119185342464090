/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
#range-picker {
  border: none;
  color: #7367f0;
  font-weight: 500;
  font-family: "Montserrat";
}

.cancel-btn {
  background-color: transparent;
  border-color: #ea5455;
  color: #ea5455;
}

.cancel-btn:hover {
  color: #ea5466;
}

.not-readed {
  color: black;
}

.custom-blue-border {
  border: 2px solid #4B55A1 !important;
}

.confirmar-btn {
  background-color: #4b55a1;
  color: #fff;
  transition: 300ms ease;
  margin-right: 10px;
}

.confirmar-btn:hover {
  color: #f4f4f4;
}

#checkboxReq:disabled {
  appearance: none;
  /* border: 2px solid #888; */
  background-color: #c2baba;
}

.border-blue {
  border: 1px solid #4B55A1;
  border-radius: 6px
}

.loading-modal .modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

@import '@src/assets/scss/landingPage';
@import '@src/assets/scss/my-investments/my-investments.scss';
@import '@src/assets/scss/messages/';
@import '@src/assets/scss/admin/admin-summary.scss';

.icon-alert {
  border-width: 5px;
}

.form-check-input:disabled {
  background-color: #d8d6de !important;
}

.card-swiper {
  background-color: #AAAEC540 !important;
  border-radius: 10px;
  margin-bottom: 0;
  

  .swiper-button-next {
    right: -10px;

    @media (min-width: 992px) {
      right: 0px;
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 15 13 11 9 7'%3E%3C/polyline%3E%3C/svg%3E");
    }

  }

  .swiper-button-prev {
    left: -10px;

    @media (min-width: 992px) {
      left: 0px;
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='14 16 10 12 14 8'%3E%3C/polyline%3E%3C/svg%3E")
    }

  }
}

