.btn-outline-darken{
  border: 1px solid #82868b !important;
  background-color: transparent;
  border-color: #82868b;
  color: #82868b;
  background-blend-mode: exclusion;
}

.btn-outline-darken::placeholder{
    color: #82868b;
}